import Cognito from "api/cognito/Cognito";

import { IPictureEntityForm } from "../api/types/PictureEntity";
import { IMediaLinkForm, IMediaSourceForm } from "../api";
import { uploadImageFromFile } from "../api/ImageApi";

export default async function NewBackendModel<
  T extends Partial<IMediaLinkForm | IMediaSourceForm | IPictureEntityForm>,
>(form: T): Promise<T> {
  const { imagePointer, imageUrl } = form as any;

  const parsed = { ...form } as any;

  /* eslint-disable */
  delete parsed.imagePointer;
  delete parsed.createdAt;
  delete parsed.updatedAt;
  delete parsed.created;
  delete parsed.modified;
  delete parsed.entity;
  delete parsed.id;
  delete parsed.creator;
  delete parsed.creatorId;
  delete parsed.creatorName;
  delete parsed.creatorImage;
  delete parsed.mediaSourceInfo;

  if(parsed.show !== undefined) parsed.show = (parsed.show? 1: 0 as any);
  const token = Cognito.getIdTokenFromStorage();
  parsed.imageUrl = imagePointer ? await uploadImageFromFile(token, imagePointer) || imageUrl : imageUrl;


  /* eslint-enable */

  return parsed;
}
